.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-title {
  font-size: 31px;
  font-weight: 700;
  padding: 12px;
  text-decoration: none;
  color: white;
}

.primary-nav {
  justify-content: space-evenly;
  align-items: center;
}

.nav-button {
  justify-content: center;
  text-decoration: none;
  background-color: #282c34;
  color: white;
  border:none;
  font-size: 21px;
  padding: 11px 11px;
  border-radius: 6px;
  font-weight: 600;
}

.nav-button:hover {
  background-color: white;
  color: #282c34;
}

.sideNav {
  display: none;
}

.sideNavButton {
  text-decoration: none;
  font-size: large;
  font-weight: 600;
  color: black;
}

@media only screen and (max-width: 768px) {
  .sideNav {
    display: unset;
  }
  .App-header {
    justify-content: left;
  }
  .App-title {
    float: left;
    align-items: center;
    justify-content: center;
  }
  .primary-nav {
    display: none;
  }
} 
